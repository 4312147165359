/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */

/**
  * This injects Tailwind's component classes and any component classes
  * registered by plugins.
  */

/**
  * This injects Tailwind's utility classes and any utility classes registered
  * by plugins.
  */

.m-0 {
  margin: 0px
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px
}

.my-1 {
  margin-top: 8px;
  margin-bottom: 8px
}

.my-2 {
  margin-top: 16px;
  margin-bottom: 16px
}

.my-3 {
  margin-top: 24px;
  margin-bottom: 24px
}

.my-5 {
  margin-top: 40px;
  margin-bottom: 40px
}

.my-0\.5 {
  margin-top: 4px;
  margin-bottom: 4px
}

.my-2\.5 {
  margin-top: 20px;
  margin-bottom: 20px
}

.mt-0 {
  margin-top: 0px
}

.mt-1 {
  margin-top: 8px
}

.mt-2 {
  margin-top: 16px
}

.mt-3 {
  margin-top: 24px
}

.mt-8 {
  margin-top: 64px
}

.mt-20 {
  margin-top: 160px
}

.mt-0\.25 {
  margin-top: 2px
}

.mt-0\.5 {
  margin-top: 4px
}

.mt-0\.75 {
  margin-top: 6px
}

.mt-1\.5 {
  margin-top: 12px
}

.mt-1\.75 {
  margin-top: 14px
}

.mt-2\.5 {
  margin-top: 20px
}

.mt-3\.5 {
  margin-top: 28px
}

.mr-1 {
  margin-right: 8px
}

.mr-2 {
  margin-right: 16px
}

.mr-auto {
  margin-right: auto
}

.mr-1\.5 {
  margin-right: 12px
}

.mb-0 {
  margin-bottom: 0px
}

.mb-1 {
  margin-bottom: 8px
}

.mb-2 {
  margin-bottom: 16px
}

.mb-3 {
  margin-bottom: 24px
}

.mb-4 {
  margin-bottom: 32px
}

.mb-5 {
  margin-bottom: 40px
}

.mb-6 {
  margin-bottom: 48px
}

.mb-7 {
  margin-bottom: 56px
}

.mb-0\.25 {
  margin-bottom: 2px
}

.mb-0\.5 {
  margin-bottom: 4px
}

.mb-1\.5 {
  margin-bottom: 12px
}

.mb-3\.5 {
  margin-bottom: 28px
}

.-mb-5 {
  margin-bottom: -40px
}

.ml-0 {
  margin-left: 0px
}

.ml-4 {
  margin-left: 32px
}

.ml-0\.75 {
  margin-left: 6px
}

.block {
  display: block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.flex-grow {
  flex-grow: 1
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.items-stretch {
  align-items: stretch
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-0 {
  gap: 0px
}

.gap-1 {
  gap: 8px
}

.gap-2 {
  gap: 16px
}

.gap-3 {
  gap: 24px
}

.gap-4 {
  gap: 32px
}

.gap-6 {
  gap: 48px
}

.gap-0\.5 {
  gap: 4px
}

.gap-1\.25 {
  gap: 10px
}

.gap-1\.5 {
  gap: 12px
}

.gap-3\.5 {
  gap: 28px
}

.gap-y-2 {
  row-gap: 16px
}

.gap-y-2\.5 {
  row-gap: 20px
}

.p-1 {
  padding: 8px
}

.p-3 {
  padding: 24px
}

.p-4 {
  padding: 32px
}

.p-6 {
  padding: 48px
}

.p-10 {
  padding: 80px
}

.px-1 {
  padding-left: 8px;
  padding-right: 8px
}

.px-2 {
  padding-left: 16px;
  padding-right: 16px
}

.px-3 {
  padding-left: 24px;
  padding-right: 24px
}

.px-10 {
  padding-left: 80px;
  padding-right: 80px
}

.px-1\.5 {
  padding-left: 12px;
  padding-right: 12px
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px
}

.py-1 {
  padding-top: 8px;
  padding-bottom: 8px
}

.py-2 {
  padding-top: 16px;
  padding-bottom: 16px
}

.py-3 {
  padding-top: 24px;
  padding-bottom: 24px
}

.py-6 {
  padding-top: 48px;
  padding-bottom: 48px
}

.py-10 {
  padding-top: 80px;
  padding-bottom: 80px
}

.py-0\.5 {
  padding-top: 4px;
  padding-bottom: 4px
}

.py-1\.5 {
  padding-top: 12px;
  padding-bottom: 12px
}

.py-2\.25 {
  padding-top: 18px;
  padding-bottom: 18px
}

.py-3\.5 {
  padding-top: 28px;
  padding-bottom: 28px
}

.pt-0 {
  padding-top: 0px
}

.pt-1 {
  padding-top: 8px
}

.pt-2 {
  padding-top: 16px
}

.pt-3 {
  padding-top: 24px
}

.pt-8 {
  padding-top: 64px
}

.pt-2\.75 {
  padding-top: 22px
}

.pt-3\.5 {
  padding-top: 28px
}

.pr-0 {
  padding-right: 0px
}

.pb-0 {
  padding-bottom: 0px
}

.pb-1 {
  padding-bottom: 8px
}

.pb-3 {
  padding-bottom: 24px
}

.pb-5 {
  padding-bottom: 40px
}

.pb-6 {
  padding-bottom: 48px
}

.pb-0\.5 {
  padding-bottom: 4px
}

.pb-0\.75 {
  padding-bottom: 6px
}

.pl-2 {
  padding-left: 16px
}

.pl-3 {
  padding-left: 24px
}

@media (min-width: 600px) {
  .sm\:mt-0 {
    margin-top: 0px
  }

  .sm\:mt-1 {
    margin-top: 8px
  }

  .sm\:mt-5 {
    margin-top: 40px
  }

  .sm\:mt-6 {
    margin-top: 48px
  }

  .sm\:mt-0\.5 {
    margin-top: 4px
  }

  .sm\:mt-1\.75 {
    margin-top: 14px
  }

  .sm\:mb-0 {
    margin-bottom: 0px
  }

  .sm\:mb-10 {
    margin-bottom: 80px
  }

  .sm\:block {
    display: block
  }

  .sm\:flex {
    display: flex
  }

  .sm\:hidden {
    display: none
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:flex-wrap {
    flex-wrap: wrap
  }

  .sm\:items-center {
    align-items: center
  }

  .sm\:justify-start {
    justify-content: flex-start
  }

  .sm\:gap-0 {
    gap: 0px
  }

  .sm\:gap-1 {
    gap: 8px
  }

  .sm\:gap-6 {
    gap: 48px
  }

  .sm\:gap-1\.5 {
    gap: 12px
  }

  .sm\:px-3 {
    padding-left: 24px;
    padding-right: 24px
  }

  .sm\:px-5 {
    padding-left: 40px;
    padding-right: 40px
  }

  .sm\:py-4 {
    padding-top: 32px;
    padding-bottom: 32px
  }

  .sm\:py-4\.5 {
    padding-top: 36px;
    padding-bottom: 36px
  }

  .sm\:pt-0 {
    padding-top: 0px
  }

  .sm\:pt-8 {
    padding-top: 64px
  }

  .sm\:pb-2 {
    padding-bottom: 16px
  }
}

@media (min-width: 960px) {
  .md\:block {
    display: block
  }

  .md\:hidden {
    display: none
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:items-start {
    align-items: flex-start
  }

  .md\:gap-1 {
    gap: 8px
  }

  .md\:gap-1\.75 {
    gap: 14px
  }
}

@media (min-width: 1280px) {
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }
}

@media (min-width: 1920px) {
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }
}
